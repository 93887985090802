<template>
  <div>
    <oReturnPage
      v-if="!$route.meta.footer && $route.meta.returnPage"
    ></oReturnPage>
    <keep-alive v-if="$route.meta.keepAlive">
      <router-view> </router-view>
    </keep-alive>
    <router-view v-else> </router-view>
    <van-tabbar
      active-color="#FF4848"
      inactive-color="#666"
      v-if="$route.meta.footer"
      v-model="active"
      :before-change="css"
      route
      fixed
      safe-area-inset-bottom
    >
      <van-tabbar-item to="/home/index" name="HomeIndex">
        <span>{{ icon.home.name }}</span>
        <template #icon="props">
          <img :src="props.active ? icon.home.active : icon.home.inactive" />
        </template>
      </van-tabbar-item>

      <van-tabbar-item to="/study/class" name="StudyClass">
        <span>{{ icon.class.name }}</span>
        <template #icon="props">
          <img :src="props.active ? icon.class.active : icon.class.inactive" />
        </template>
      </van-tabbar-item>
      <!-- url="https://appg5q9tdm86610.h5.xiaoeknow.com/faceTeach/get_school_timetable" -->
      <!-- to="/study/index" -->
      <van-tabbar-item name="allProduct" to="/list/allproduct">
        <span>{{ icon.list.name }}</span>
        <template #icon="props">
          <img :src="props.active ? icon.list.active : icon.list.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item
        v-if="$store.state.cart.cart_num == 0"
        to="/cart/index"
        name="Cart"
      >
        <span>{{ icon.cart.name }}</span>
        <template #icon="props">
          <img :src="props.active ? icon.cart.active : icon.cart.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item
        :badge="$store.state.cart.cart_num"
        to="/cart/index"
        name="Cart"
        v-else
      >
        <span>{{ icon.cart.name }}</span>
        <template #icon="props">
          <img :src="props.active ? icon.cart.active : icon.cart.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/me/index" name="Me">
        <span>{{ icon.me.name }}</span>
        <template #icon="props">
          <img :src="props.active ? icon.me.active : icon.me.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from "vant";
import { getQueryVariable } from "@get/util";
import oReturnPage from "./return";
export default {
  data() {
    return {
      cart_badge: 0,
      active: "HomeIndex",
      css: async (act) => {
        let setup = await this.$store.getters;

        if (setup.getSetupLogin) {
          return false;
        } else {
          return true;
        }
      },
      icon: {
        home: {
          name: "商城",
          active:
            "https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2022050717044587550.png",
          inactive:
            "https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2022050717044567678.png",
        },
        class: {
          name: "课程",
          active:
            "https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2022050717044524976.png",
          inactive:
            "https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2022050717044575950.png",
        },
        list: {
          name: "分类",
          active:
            "https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2022050717044522430.png",
          inactive:
            "https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2022050717044598191.png",
        },
        cart: {
          name: "购物车",
          active:
            "https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2022050717044591772.png",
          inactive:
            "https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2022050717044523337.png",
        },
        me: {
          name: "我的",
          active:
            "https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2022050717044590454.png",
          inactive:
            "https://image.shop.19jiang.cn/home/store/goods/2/alioss_2_2022050717044593826.png",
        },
      },
    };
  },
  created() {
    let page_type = getQueryVariable("page_type") || 1;
    if (page_type != 1) {
      this.$route.meta.footer = false;
      // this.$set(this.$route.meta, "footer", false);
    }
  },
  methods: {
    async getCartNum() {
      this.$store.dispatch("doSetCartNum", "carnum");
    },
    onChange(active) {
      return false;
    },
  },
  mounted() {
    this.getCartNum();
  },
  components: {
    vanTabbar: Tabbar,
    vanTabbarItem: TabbarItem,
    oReturnPage,
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";
.van-tabbar--fixed {
  position: fixed !important;
  z-index: 999;
}
::v-deep {
  .van-tabbar {
    height: rem(112);
  }
  .van-tabbar-item__icon {
    > img {
      width: rem(56);
      height: rem(56);
    }
  }
}
</style>
